!<template>
  <div class="publication">
    <h1 class="title">Publication</h1>
    <div class="essay-group">
      <div class="essay">
        <div class="essay-title">
          <h2>
            FP-GNN: a versatile deep learning architecture for enhanced
            molecular property prediction
          </h2>
        </div>
        <div class="essay-content">
          <session class="essay-text">
            <div class="essay-author">
              <p>
                Hanxuan Cai, Huimin Zhang, Duancheng Zhao, Jingxing Wu, Ling
                Wang*
              </p>
            </div>
            <div class="essay-summary">
              Deep learning is an important method for molecular design and
              exhibits considerable ability to predict molecular properties,
              including physicochemical, bioactive, and ADME/T (absorption,
              distribution, metabolism, excretion, and toxicity) properties. In
              this study, we advanced a novel deep learning architecture, termed
              FP-GNN, which combined and simultaneously learned information from
              molecular graphs and fingerprints. To evaluate the FP-GNN model,
              we conducted experiments on 13 public datasets, an unbiased
              LIT-PCBA dataset, and 14 phenotypic screening datasets for breast
              cell lines. Extensive evaluation results showed that compared to
              advanced deep learning and conventional machine learning
              algorithms, the FP-GNN algorithm achieved state-of-the-art
              performance on these datasets. In addition, we analyzed the
              influence of different molecular fingerprints, and the effects of
              molecular graphs and molecular fingerprints on the performance of
              the FP-GNN model. Analysis of the anti-noise ability and
              interpretation ability also indicated that FP-GNN was competitive
              in real-world situations.
            </div>
          </session>
          <session class="essay-picture"
            ><img src="../assets/Publication/paper1_img.png"
          /></session>
        </div>
      </div>
      <div class="essay">
        <div class="essay-title">
          <h2>
            A multi-task FP-GNN framework enables accurate prediction of
            selective PAPR inhibitors
          </h2>
        </div>
        <div class="essay-content">
          <session class="essay-text">
            <div class="essay-author">
              <p>
                Daiqiao Ai,1,‡ Jingxing Wu,1,‡ Hanxuan Cai1, Duancheng Zhao1,
                Yihao Chen1, Jiajia Wei1, Jianrong Xu3,4, Jiquan Zhang2,* and
                Ling Wang1,*
              </p>
            </div>
            <div class="essay-summary">
              PARP (poly ADP-ribose polymerase) family is a crucial DNA repair
              enzyme that responds to DNA damage, regulates apoptosis, and
              maintains genome stability; therefore, PARP inhibitors represent a
              promising therapeutic strategy for the treatment of various human
              diseases including COVID-19. In this study, multi-task FP-GNN deep
              learning framework was proposed to predict the inhibitory activity
              of molecules against four PARP isoforms (PARP-1, PARP-2, PARP-5A,
              and PARP-5B). Compared with baseline predictive models based on
              three conventional machine learning methods such as RF, SVM, and
              XGBoost, as well as six deep learning algorithms such as DNN,
              Attentive FP, MPNN, GAT, GCN, and D-MPNN, the evaluation results
              indicate that the multi-task FP-GNN method achieves the best
              performance with the highest average BA, F1, and AUC values of
              0.753 ± 0.033, 0.910 ± 0.045, and 0.888 ± 0.016 for the test set.
              In addition, Y-scrambling testing successfully verified that the
              model was not results of chance correlation. More importantly, the
              interpretability of the multi-task FP-GNN model enabled the
              identification of key structural fragments associated with the
              inhibition of each PARP isoform. To facilitate the use of the
              multi-task FP-GNN model in the field, an online webserver called
              PARPi-Predict and its local version software were created to
              predict whether compounds bear potential inhibitory activity
              against PARPs, thereby contributing to design and discover better
              selective PARP inhibitors.
            </div>
          </session>
          <session class="essay-picture"
            ><img src="../assets/Publication/paper2_img.png"
          /></session>
        </div>
      </div>
      <div class="essay">
        <div class="essay-title">
          <h2>VDDB: a comprehensive database for antiviral drugs</h2>
        </div>
        <div class="essay-content">
          <session class="essay-text">
            <div class="essay-author">
              <p>
                Shunming Tao1, †, Yihao Chen1, †, Jingxing Wu1, †, Duancheng
                Zhao1, Hanxuan Cai1 and Ling Wang1,*
              </p>
            </div>
            <div class="essay-summary">
              Virus infection is one of the major diseases that seriously
              threaten human health, especially many accidental and innocent
              deaths caused by the outbreak of SARS-CoV-2 infection in 2019. It
              is undeniable that drug treatment is still the core of preventing
              and treating viral infections. To meet the growing demand for
              mining and sharing data resources related to antiviral drugs and
              to accelerate the design and discovery of new antiviral drugs, we
              presented an open-access antiviral drug database (VDDB), which, to
              the best of our knowledge, is the first comprehensive dedicated
              resource for experimentally verified potential drugs/molecules
              based on manually curated data. Currently, VDDB highlights 848
              clinical vaccines, 199 clinical antibodies, as well as over
              710,000 small molecules targeting 39 medically important viruses
              including SARS-CoV-2. Furthermore, our database stores
              approximately 3 million records of pharmacological data for these
              collected potential antiviral drugs/molecules, involving 314 cell
              infection-based phenotypic and 234 target-based genotypic assays.
              Based on these annotated pharmacological data, our database allows
              users to browse, search and download reliable information about
              these collects for various viruses of interest. In particular,
              VDDB also integrates 57 cell infection- and 117 target-based
              associated high-accuracy machine learning models to support
              various antivirals identification-related tasks, such as compound
              activity prediction, virtual screening, drug repositioning and
              target fishing. We anticipate that VDDB, as a one-stop knowledge
              base for the research community, will be committed to enabling and
              accelerating antiviral drug discovery. VDDB is freely accessible
              at http://vddb.idruglab.cn.
            </div>
          </session>
          <session class="essay-picture"
            ><img src="../assets/Publication/paper3_img.png"
          /></session>
        </div>
      </div>
      <div class="essay">
        <div class="essay-title">
          <h2>
            Large-scale comparative assessment of machine learning tools for the
            profiling prediction of kinase inhibitors
          </h2>
        </div>
        <div class="essay-content">
          <session class="essay-text">
            <div class="essay-author">
              <p>
                Jiangxia Wu1,‡, Yihao Chen1,‡, Jingxing Wu1,‡, Duancheng Zhao,
                Ling Wang1,*
              </p>
            </div>
            <div class="essay-summary">
              The kinase family is one of the largest target families in the
              human genome. By Kinome-wide screening, it can provide
              structure–activity connections for hundreds of targets at the same
              time, simultaneously facilitates the development of drugs for the
              treatment of various diseases. Machine learning plays a key role
              in theoretical prediction of kinase drug selectivity. In this
              study, we systematically compare the performance of mainstream
              machine learning methods in kinase profile prediction, including
              traditional machine learning methods and newly adopted graph
              convolutional algorithms. Over 47436 single models were
              constructed based on three types of molecular representations
              including fingerprints, molecular descriptors, and graphs. Our
              study found that FPGNN achieves the best predictive performance.
              On average, predictions of models based on fingerprint algorithms
              are close to that based on molecular descriptors in terms of
              prediction accuracy and computational efficiency. Common deep
              learning algorithm models that take molecular graphs as input,
              including GCN, GAT, MPNN, Attentive FP, and chemprop, with average
              AUC value of about 0.691, are not as good as shallow methods that
              based on 5 fingerprints and molecular descriptors. It should be
              pointed out that due to the limited training data of many kinases,
              the training capabilities of graph convolutional algorithms are
              limited. However, FP-GNN, which combines molecular map and
              molecular fingerprint features, achieved good prediction results.
              Based on our results, the optimal model FPGNN is selected to
              develop an online prediction platform. Samoye-idruglab enables a
              full kinase-wide activity prediction and analyze the overall
              selectivity and kinase subfamily specificity of small molecules
              based on the predicted results. It can be helped for the design of
              novel kinase inhibitors or drug repositioning.
            </div>
          </session>
          <session class="essay-picture"
            ><img src="../assets/Publication/paper4_img.png"
          /></session>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.publication {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}
.title {
  font-size: 24pt;
  margin: 20px 0;
  padding: 0 10px;
  text-align: center;
}
.essay-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  color: black;
}
.essay {
  margin: 20px 0px;
  background: white;
  width: 70%;
  box-shadow: -8px 10px 3px black;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.essay-title {
  width: 90%;
  flex-wrap: nowrap;
  margin-top: 20px;
}
.essay-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 350px;
}
.essay-content {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}
.essay-text {
  width: 50%;
}
.essay-picture {
  width: 45%;
}
.essay-author {
  height: 40px;
  overflow: hidden;
  word-break: break-word;
  text-align: justify;
  text-overflow: ellipsis;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.essay-summary {
  height: 300px;
  overflow: hidden;
  word-break: break-word;
  text-align: justify;
  text-overflow: ellipsis;
  line-height: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 15;
}
.essay-picture {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.essay-picture img {
  width: 100%;
}
</style>